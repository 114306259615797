<template>
  <div>
    <div class="chart-info">
      <ul class="chart-legend">
        <li><span class="chart-legend-color chart-legend-color-clean"></span> Tasks</li>
<!--        <li><span class="chart-legend-color chart-legend-color-profane"></span> Profane</li>-->
      </ul>

      <ul class="chart-span">
        <li :class="{'active': (timespan === '7d')}">
          <button tabindex="0" role="button" title="7 days" @click="updateTimespan('7d')">7d</button>
        </li>
        <li :class="{'active': (timespan === '30d')}">
          <button tabindex="0" role="button" title="30 days" @click="updateTimespan('30d')">30d</button>
        </li>
        <li :class="{'active': (timespan === '1yr')}">
          <button tabindex="0" role="button" title="1 year" @click="updateTimespan('1yr')">1yr</button>
        </li>
      </ul>
    </div>

    <canvas ref="canvas" width="900" height="350"></canvas>
  </div>
</template>

<script>
  import Chart from "chart.js";

  Chart.plugins.register({
    afterDraw: function(chart) {
      if (chart.data.datasets[0].data.every(item => item === 0)) {
        let ctx = chart.chart.ctx;
        let width = chart.chart.width;
        let height = chart.chart.height;

        // chart.clear();
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font ="16px 'Helvetica Neue', sans-serif";
        ctx.fillText('No usage data to display yet', width / 2, height / 2);
        ctx.restore();
      }
    }
  });

  export default {
    props: {
      labels: {
        type: Array,
        required: true
      },
      calls: {
        type: Array,
        required: true
      },
      timespan: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        chart: null
      }
    },
    watch: {
      labels(newValue, oldValue) {
        this.chart.data.labels = newValue;
        this.chart.update();
      },
      calls(newValue, oldValue) {
        this.chart.data.datasets[0].data = newValue;
        // this.chart.data.datasets[1].data = newValue;
        this.chart.update();
      }
    },
    methods: {
      updateTimespan(timespan) {
        this.$emit('updated', timespan)
      }
    },
    mounted() {
      const options = {
        legend: {
          display: false
        },
        responsive: true,
        hover: {
          animationDuration: 0
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        scales: {
          xAxes: [{stacked: true}],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true,
              min: 0,
              precision: 0
            }
          }]
        }
      };

      const ctx = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: 'Tasks',
              data: this.calls,
              backgroundColor: '#009868',
              borderColor: '#009868',
              borderWidth: 1,
              barPercentage: 0.5,
              maxBarThickness: 80
            },
            // {
            //   label: 'Profane',
            //   data: this.calls,
            //   backgroundColor: '#FFCEB3',
            //   borderColor: '#FFCEB3',
            //   borderWidth: 1
            // },
          ]
        },
        options: options
      })
    }
  }
</script>

<style lang="scss">
  @import "src/scss/variables";

  .chart-info {
    color: rgba(#000, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    margin: 0 0 8px;

    ul {
      margin: 0 0 16px 0;
      font-size: 16px;
    }
  }

  .chart-legend {
    display: inline-flex;
    padding: 0;
    list-style: none;

    li:not(:last-child) {
      margin: 0 24px 0 0;
    }

    &-color {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      display: inline-block;
      margin: 0 8px 0 0;
      &-clean { background: $brand-color; }
      &-profane { background: $alert-color-light70; }
    }
  }

  .chart-span {
    display: inline-flex;
    padding: 0;
    list-style: none;

    li:not(:last-child) {
      margin: 0 8px 0 0;
    }

    button {
      font-family: $family-sans-serif;
      font-size: 16px;
      cursor: pointer;
      color: rgba(#000, 0.8);
      padding: 4px 8px;
      border: 0;
      border-bottom: 2px solid transparent;
      background: none;

      &:hover {
        background: rgba(#000, 0.05);
      }
    }

    li.active button {
      color: $brand-color;
      border-bottom: 2px solid $brand-color;
    }
  }
</style>
